import React, { type FC } from 'react';
import { type WidgetProps } from '@wix/yoshi-flow-editor';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { Form } from './Form';
import { type ControllerProps } from '../types';

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  return (
    <FormViewerContext {...props}>
      <Form {...props} />
    </FormViewerContext>
  );
};

export default Widget;
