import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  backgroundColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
});
