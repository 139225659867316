import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useFormPreview, type FormValues } from '@wix/form-viewer/widget';

interface FormPreviewProps {
  formId: string;
  formValues: FormValues;
}

export const FormPreview = ({ formId, formValues }: FormPreviewProps) => {
  const formattedValues = useFormPreview(formId, formValues);

  if (!formattedValues) {
    return <div>Placeholder for fallback</div>;
  }

  return (
    <ul data-hook="form-preview">
      {formattedValues.map(({ label, value }) => {
        if (typeof value === 'string') {
          return (
            <li data-hook="form-preview-item">
              <Text>
                {label} - {value}
              </Text>
            </li>
          );
        }
        if (typeof value === 'number') {
          return (
            <li data-hook="form-preview-item">
              <Text>
                {label} - {value}
              </Text>
            </li>
          );
        }
        if (typeof value === 'boolean') {
          return (
            <li data-hook="form-preview-item">
              <Text>{label}</Text>
            </li>
          );
        }
        if (Array.isArray(value)) {
          return (
            <div data-hook="form-preview-item">
              <Text>{label}:</Text>
              <ul>
                {value.map((item) => {
                  if (typeof item === 'string') {
                    return (
                      <li>
                        <Text>{item}</Text>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          );
        }
        return null;
      })}
    </ul>
  );
};
