import React, { type FC, useEffect } from 'react';

interface AppLoadedParams {
  appLoaded: boolean;
  setAppLoaded: () => void;
}

export const AppLoaded: FC<AppLoadedParams> = ({ appLoaded, setAppLoaded }) => {
  useEffect(() => setAppLoaded?.(), [setAppLoaded]);

  return appLoaded ? (
    <div data-hook="form-app-loaded" style={{ display: 'none' }}></div>
  ) : null;
};
