import React, { type FC, useRef, useState } from 'react';
import { Button } from 'wix-ui-tpa/cssVars';
import {
  useBi,
  useTranslation,
  type WidgetProps,
} from '@wix/yoshi-flow-editor';
import {
  type FormValues,
  FormViewer,
  type FormViewerHandle,
} from '@wix/form-viewer/widget';
import { type ValidationError } from '@wix/form-validator';
import { FormPreview } from '../../../shared/FormPreview';
import { type ControllerProps } from '../../types';

import styles from './index.scss';
import { AppLoaded } from '../../../shared/AppLoaded/AppLoaded';

export const Form: FC<WidgetProps<ControllerProps>> = ({
  appLoaded,
  setAppLoaded,
  submitForm,
  formId,
}) => {
  const formViewer = useRef<FormViewerHandle>(null);
  const [values, setValues] = useState<FormValues>({});
  const [previewValues, setPreviewValues] = useState({});
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const bi = useBi();
  const handleSubmit = async () => {
    if (!errors?.length) {
      setPreviewValues(values || {});
      submitForm(values);
    }
  };

  const handleValidate = async () => {
    await formViewer.current?.validate();
  };

  const { i18n } = useTranslation();

  return (
    <div className={styles.container} data-hook="SledTests-wrapper">
      <FormViewer
        i18n={i18n}
        ref={formViewer}
        formId={formId}
        values={values}
        errors={errors}
        onValidate={setErrors}
        onChange={setValues}
        bi={bi}
      />
      <Button
        data-hook="validate-button"
        className={styles.button}
        onClick={handleValidate}
      >
        Validate
      </Button>
      <Button
        data-hook="external-submit-button"
        className={styles.button}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <div className={styles.previewContainer}>
        <FormPreview formId={formId} formValues={previewValues} />
      </div>
      <AppLoaded appLoaded={appLoaded} setAppLoaded={setAppLoaded} />
    </div>
  );
};
